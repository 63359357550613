<template>
  <div class="flex column center" style="margin-bottom:4rem;">
    <div class="new_box">
      <div class="titlee">历思通过检验检测机构资质认定（CMA认证）</div>
      <p class="time">2017.03.16</p>
      <p class="space_content">
        2017年2月，历思正式获得由福建省质量技术监督局颁发的检验检测机构资质认定（CMA认证）证书，证书编号：171307030017。</p>
      <p class="space_content">
        关于CMA认证：中国计量认证简称“CMA”，英文译名为“China Metrology Accreditation”的缩写。是根据中华人民共和国计量法的规定，由省级以上人民政府计量行政部门对检测机构的检测能力及可靠性进行的一种全面的认证及评价。取得计量认证合格证书的检测机构，允许其在检验报告上使用CMA标记；有CMA标记的检验报告可用于产品质量评价、成果及司法鉴定，具有法律效力。
      </p>
      <p class="space_content">
        历思长期致力于探索智慧物证科技，提供可信鉴定服务。CMA认证的通过，是历思不断加强管理、规范运作的坚实一步。
      </p>


      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/7-1.png')">
      </div>

    </div>
    <div class="flex wrap .col442" style="width:100%;text-align:left;margin-top:1rem">
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex" style="margin-right:10%">
          <p style="white-space:nowrap">上一篇：</p>
          <router-link class="a" :to="'/news/lsnews/6'"><p class="ellipsis1">秦天宝等：我国土壤污染防治立法探究</p>
          </router-link>
        </div>
      </div>
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex">
          <p style="white-space:nowrap">下一篇：</p>
          <router-link class="a" :to="'/news/lsnews/8'"><p class="ellipsis1">历思受邀为党员律师讲解司法鉴定实务专题学习活动</p>
          </router-link>

        </div>
      </div>
      <div class="col2 flex  end1 center">
        <router-link class="a" style="color:#fff" :to="'/news/lsnews'">
          <el-button type="primary">返回列表</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  },
}
</script>
<style>
.redText {
  background: red;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.titleText {
  font-size: 20px;
  font-weight: 600;
}
</style>
